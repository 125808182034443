const cubeImg = [
  {
    id: 0,
    src: "./assets/everydayOnIc.png",
  },
  {
    id: 1,
    src: "./assets/showYourself.png",
  },
  {
    id: 2,
    src: "./assets/youAreTheNextStar.png",
  },
  {
    id: 3,
    src: "./assets/onTheStage.png",
  },
];
const cubeTextImg = [
  {
    id: 0,
    src: "./assets/everydayOnIcText.png",
  },
  {
    id: 1,
    src: "./assets/showYourselfText.png",
  },
  {
    id: 2,
    src: "./assets/YOUARETHEONEText.png",
  },
  {
    id: 3,
    src: "./assets/onTheStageText.png",
  },
];

const horizontalImg = [
  {
    id: 0,
    src: "./assets/itsYourTurnText.png",
  },
  {
    id: 1,
    src: "./assets/itsYourTurn.png",
  },
];

const verticalImg = [
  {
    id: 0,
    src: "./assets/Be_the_Challenger.png",
  },
  {
    id: 1,
    src: "./assets/Make_Dreams_Come_True.png",
  },
  {
    id: 2,
    src: "./assets/One_Step_at_a_Time.png",
  },
  {
    id: 3,
    src: "./assets/IC_will_be_with_You.png",
  },
];
export { cubeImg, cubeTextImg, horizontalImg, verticalImg };
