import React from "react";
import "./footer.css";

export default function Footer() {
  return (
    <section className="footer_Msize">
      <header />
      <div className="imgWrapper">
        <img className="helloIc" src="assets/helloIc.png" alt="helloIc" />
        <img className="beTheNextStar" src="assets/K-Contents_Production.png" alt="beTheNextStar" />
        <img className="iconStar" src="assets/iconStar.png" alt="iconStar" />
      </div>

      <div className="divide" />

      <div className="footer">
        <div className="left">
          <div>
            <span className="company">IC KOREA (I Culture Korea)</span>
          </div>
          <div>
            <span className="ceo">Art Director : Noh Jun Seong</span>
          </div>
          <div>
            <span className="email">E-mail : contact@ic-korea.com</span>
          </div>
          <div>
            <p>102, 14-16, Gyeongsu-daero 443beon-gil, Paldal-gu, Suwon-si, Gyeonggi-do, Republic of Korea</p>
          </div>
        </div>

        <div className="right">
          <div>
            <p>EVERYDAY ON IC</p>
            <ul className="sns_Icon">
              <li><a href="https://www.instagram.com" target="_blank" ><img className="Instagram" src="assets/Instagram.png" alt="Instagram_icon" /></a></li>
              <li><a href="https://www.facebook.com/" target="_blank" ><img className="Facebook" src="assets/Facebook.png" alt="Facebook_icon" /></a></li>
              <li><a href="https://www.youtube.com/" target="_blank" ><img className="Tiktok" src="assets/YouTube.png" alt="Youtube_icon" /></a></li>
              <li><a href="https://www.tiktok.com/" target="_blank" ><img className="Tiktok" src="assets/Tiktok.png" alt="Tiktok_icon" /></a></li>
            </ul>
          </div>
          <div>
           
          </div>
        </div>
      </div>
    </section>
  );
}
